import { Component, OnInit } from '@angular/core';
import { AuthService } from './pages/auth/auth.service';
import { FSMiniLoaderService } from './shared/modules/fs-mini-loader/fs-mini-loader.service';
import { GlobalService } from './shared/services/global.service';
import { sleep } from './shared/helpers/helper-functions';
import { AuthState } from './shared/enums/auth-state.enum';
import { FsToastService } from './shared/modules/fs-popups/fs-toast/fs-toast.service';
import { ChangePasswordObject } from './shared/models/user.model';
import { PageFrameService } from './shared/modules/fs-page-frame/fs-page-frame.service';
import { LoggerService } from './shared/services/logger.service';
import { Convert } from './shared/helpers/convert';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  readonly authState = AuthState;

  constructor(
    public authService: AuthService,
    public loaderService: FSMiniLoaderService,
    private globalService: GlobalService,
    private toastService: FsToastService,
    public pageFrameService: PageFrameService,
    private loggerService: LoggerService
  ) {
    this.loggerService.printVersion();

    let queryArr = window.location.href.split('?');
    let queryObj = Convert.urlParamsToObject(queryArr[1]) ?? 0;

    if (queryObj?.code?.length && queryObj?.username?.length) {
      this.authService.changePasswordUrlObject = new ChangePasswordObject({
        ...queryObj,
      });
    }
  }

  async ngOnInit(): Promise<void> {
    await this.checkUserAuth();
  }

  async checkUserAuth(): Promise<void> {
    try {
      this.loaderService.loader = true;
      this.authService.isAuthenticate = AuthState.False;

      const result = await this.authService.isTokenValid();
      if (!result) {
        await this.authService.unautorized();
        return;
      }

      const isAuthenticate =
        await this.authService.isAuthenticatedCognitoGroups();

      if (!isAuthenticate) {
        await this.authService.unautorized();
        this.toastService.show('ERROR', 'User not compete admin');
        return;
      }

      const user = await this.authService.getUserData();
      if (!user) {
        await this.authService.unautorized();
        return;
      }

      await this.globalService.loadResources();
      if (!this.globalService.resources) {
        await this.authService.unautorized();
        this.toastService.show('ERROR', 'Resources not available');
        return;
      }

      this.globalService.user = user;

      await sleep(100);

      this.authService.isAuthenticate = AuthState.True;

      // if (queryObj) {
      //   //Query checked
      // }
    } catch (error) {
      console.error(error);
      await this.authService.unautorized();
    } finally {
      this.loaderService.loader = false;
    }
  }
}
