import {
  AnyWP,
  GuidPattern,
  HasNumber,
  HasSpecialCharacter,
  LowerCase,
  MoreThan2WP,
  StartEndWP,
  UpperCase,
} from '../constants/regex-patterns';

export class ValidationHelper {
  public static isValidGUID(value: string): boolean {
    return GuidPattern.test(value);
  }

  public static password(value: string): boolean {
    return (
      value?.length &&
      new RegExp(LowerCase.source).test(value) &&
      new RegExp(UpperCase.source).test(value) &&
      new RegExp(HasNumber.source).test(value) &&
      new RegExp(HasSpecialCharacter.source).test(value) &&
      value.length > 7 &&
      value.length < 50
    );
  }

  public static hasMoreThan2WP(value: string): boolean {
    return MoreThan2WP.test(value);
  }

  public static hasStartEndWP(value: string): boolean {
    return StartEndWP.test(value);
  }

  public static hasAnyWP(value: string): boolean {
    return AnyWP.test(value);
  }

  public static fullCheckNumber(value: number): boolean {
    try {
      if (
        value === 0 ||
        (value !== undefined && value !== null && !Number.isNaN(value))
      )
        return true;

      return false;
    } catch (error) {
      return false;
    }
  }
}
